* {
  font-family: "Poppins", sans-serif;
}
body {
	background-color: #1f1f1f;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
